// src/pages/AccountSetup.tsx

import React, { useState, useEffect } from 'react';
import { Layout } from '@components/Layout';
import { useStores } from '@stores';
import { Spinner, Heading, Box, Button, Text } from '@components/Primitives';
import { Form } from '@components/Form';
import { navigate } from '@reach/router';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { FormattedMessage as M } from 'gatsby-plugin-intl'; // Import FormattedMessage

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

interface AccountSetupProps {
  data: {
    markdownRemark: {
      html: string;
    };
  };
}

const AccountSetup: React.FC<AccountSetupProps> = observer(({ data }) => {
  const { authStore } = useStores();
  const [step, setStep] = useState<number>(1); // Start at step 1
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>(''); // Store translation IDs for errors

  // Translation helper functions
  const t = (id: string) => `pages.accountSetup.${id}`;
  const tCommon = (id: string) => `common.${id}`;

  // Get the markdown content
  const { html } = data.markdownRemark || {};

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (code && state) {
      // Store the state token
      authStore.stateToken = state;

      // Proceed to validate user info
      authStore
        .getValidatedUserInfo({ code, state })
        .then((response: any) => {
          setLoading(false);
          if (authStore.activeAccount) {
            // Store the password reset token
            authStore.passwordResetToken = response.passwordResetToken;
            setStep(2); // User has an active account
          } else {
            setStep(3); // User does not have an active account
          }
        })
        .catch((err: any) => {
          setLoading(false);
          setError(t('errorFailedToRetrieveUserInfo'));
          console.error('Error in getValidatedUserInfo:', err);
        });
    } else {
      // No code and state, initiate authentication process
      authStore
        .getAuthUrl(window.location.origin + '/account-setup/')
        .then(() => {
          setLoading(false);
          setStep(1); // Prompt user to start authentication
        })
        .catch((err: any) => {
          setLoading(false);
          setError(t('errorFailedToInitiateAuthentication'));
          console.error('Error getting authUrl:', err);
        });
    }
  }, [authStore]);

  const handleStartAuthentication = () => {
    window.location.href = authStore.authUrl;
  };

  const handleResetPassword = () => {
    // Proceed to the password reset form
    setStep(5); // New step for password reset
  };

  const handlePasswordResetSubmit = async (data: any, callback: Function) => {
    try {
      const { newPassword, confirmPassword } = data;
      if (newPassword !== confirmPassword) {
        setError(tCommon('passwordsDoNotMatch'));
        return;
      }
      await authStore.updatePassword(authStore.passwordResetToken, newPassword, callback);
      setStep(6); // Password reset successful
    } catch (error) {
      setError(t('errorFailedToResetPassword'));
      console.error('Error in updatePassword:', error);
    }
  };

  const handleCreateNewAccount = () => {
    // Proceed to account creation flow
    setStep(3);
  };

  const handleRegistrationSubmit = async (data: any, callback: Function) => {
    try {
      const registrationData = {
        ...data,
        state: authStore.stateToken, // Ensure state is included
      };
      await authStore.createUser(registrationData, callback);
      setStep(4); // Registration successful
    } catch (error) {
      setError(t('errorFailedToCreateAccount'));
      console.error('Error in createUser:', error);
    }
  };

  const validateForm = ({
    email,
    password,
    passwordAgain,
  }: {
    email?: string;
    password?: string;
    passwordAgain?: string;
  }) => {
    const errors: { [key: string]: string } = {};
    if (!email) {
      errors.email = t('emailRequired');
    }
    if (!password) {
      errors.password = t('passwordRequired');
    }
    if (password !== passwordAgain) {
      errors.passwordAgain = tCommon('passwordsDoNotMatch');
    }
    return errors;
  };

  const validatePasswordResetForm = ({
    newPassword,
    confirmPassword,
  }: {
    newPassword?: string;
    confirmPassword?: string;
  }) => {
    const errors: { [key: string]: string } = {};
    if (!newPassword) {
      errors.newPassword = t('passwordRequired');
    }
    if (newPassword !== confirmPassword) {
      errors.confirmPassword = tCommon('passwordsDoNotMatch');
    }
    return errors;
  };

  if (loading) {
    return (
      <Layout twoColumnLayout>
        <Box pl="2" pr="2" textAlign="center">
          <Spinner />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout twoColumnLayout>
        <Box pl="2" pr="2" textAlign="center">
          <Text color="red">
            <M id={error} />
          </Text>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout twoColumnLayout>
      <Box pl="2" pr="2">
        {step === 1 && (
          <>
            <Heading level="3" textAlign="center">
              <M id={t('title')} />
            </Heading>
            <Text textAlign="center" mt="2">
              <M id={t('instructions')} />
            </Text>
            <ButtonContainer>
              <Button onClick={handleStartAuthentication}>
                <M id={t('startAuthenticationButton')} />
              </Button>
            </ButtonContainer>
          </>
        )}

        {step === 2 && (
          <>
            <Heading level="3" textAlign="center">
              <M id={t('welcomeBackTitle')} />
            </Heading>
            <Text textAlign="center" mt="2">
              <M id={t('yourEmail')} values={{ email: authStore.validatedUser.email }} />
            </Text>
            <Text textAlign="center" mt="2">
              <M id={t('resetPasswordPrompt')} />
            </Text>
            <ButtonContainer>
              <Button onClick={handleResetPassword}>
                <M id={t('resetPasswordYesButton')} />
              </Button>
              <Button variant="secondary" onClick={handleCreateNewAccount}>
                <M id={t('createNewAccountButton')} />
              </Button>
            </ButtonContainer>
          </>
        )}

        {step === 3 && (
          <>
            <Heading level="3" textAlign="center">
              <M id={t('createNewAccountTitle')} />
            </Heading>
            <Form
              id="register"
              fields={[
                {
                  id: 'email',
                  name: t('email'),
                  type: 'email',
                  required: true,
                  initialValue: authStore.validatedUser.email || '',
                },
                {
                  id: 'password',
                  name: t('password'),
                  type: 'password',
                  required: true,
                },
                {
                  id: 'passwordAgain',
                  name: t('confirmPassword'),
                  type: 'password',
                  required: true,
                },
                // Include other necessary fields, possibly pre-filled from identityToken
              ]}
              values={{
                email: authStore.validatedUser.email || '',
                firstName: authStore.validatedUser.identityToken?.given_name || '',
                lastName: authStore.validatedUser.identityToken?.family_name || '',
              }}
              onSubmit={handleRegistrationSubmit}
              submitText={t('createCredentials')}
              validate={validateForm}
              styles={['centerLabels', 'singleColumn']}
            />
          </>
        )}

        {step === 4 && (
          <>
            <Heading level="3" textAlign="center">
              <M id={t('accountCreatedTitle')} />
            </Heading>
            <Text textAlign="center" mt="2">
              <M id={t('accountActivationMessage')} />
            </Text>
            <ButtonContainer>
              <Button onClick={() => navigate('/login')}>
                <M id={t('goToLogin')} />
              </Button>
            </ButtonContainer>
          </>
        )}

        {step === 5 && (
          <>
            <Heading level="3" textAlign="center">
              <M id={t('resetPasswordTitle')} />
            </Heading>
            <Form
              id="reset-password"
              fields={[
                {
                  id: 'newPassword',
                  name: t('newPassword'),
                  type: 'password',
                  required: true,
                },
                {
                  id: 'confirmPassword',
                  name: t('confirmPassword'),
                  type: 'password',
                  required: true,
                },
              ]}
              values={{}}
              onSubmit={handlePasswordResetSubmit}
              submitText={t('resetPasswordButton')}
              validate={validatePasswordResetForm}
              styles={['centerLabels', 'singleColumn']}
            />
            {error && (
              <Text color="red" textAlign="center" mt="2">
                <M id={error} />
              </Text>
            )}
          </>
        )}

        {step === 6 && (
          <>
            <Heading level="3" textAlign="center">
              <M id={t('passwordResetSuccessTitle')} />
            </Heading>
            <Text textAlign="center" mt="2">
              <M id={t('passwordActivationMessage')} />
            </Text>
            <ButtonContainer>
              <Button onClick={() => navigate('/login')}>
                <M id={t('goToLogin')} />
              </Button>
            </ButtonContainer>
          </>
        )}
      </Box>
    </Layout>
  );
});

export default AccountSetup;

// GraphQL query to get the markdown content
export const pageQuery = graphql`
  query AccountSetupQuery {
    markdownRemark(frontmatter: { path: { eq: "/account-setup/completed/" } }) {
      html
    }
  }
`;
